/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import url('https://fonts.googleapis.com/css?family=K2D:200,300,400,500,600,700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
// regular style toast 
@import '~ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/default.theme.css";
// bootstrap style toast 
// or import a bootstrap 4 alert styled design (SASS ONLY) 
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions 
body {
    font-family: 'Open Sans', sans-serif!important;
    margin: 0;
}
button, input, optgroup, select, textarea {
    font-family: 'Open Sans', sans-serif!important;
}

a:hover,
a:focus {
    text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
    padding: 0;
    margin: 0;
}
h2,h3,h4,h5,h6 {
    font-weight: 600;
    color: #222;
}
.mat-expansion-panel-header {
    font-family: 'Open Sans', sans-serif!important;
}
.page-title {
    font-size: 24px;
    font-weight: 600;
    color: #222;
    text-transform: capitalize;
}
.header {
    font-size: 20px;
    font-weight: 600;
    color: #222;
}
.sub-header {
    font-size: 16px;
    font-weight: 600;
    color: #222;
}
.sub-sub-header {
    font-size: 14px;
    font-weight: 600;
    color: #222;
}

.text16 {
    font-size: 16px;
    font-weight: normal;
    color: #222;
}
.text14 {
    font-size: 14px;
    font-weight: normal;
    color: #222;
    line-height: 1.43;
}
.text12 {
    font-size: 12px;
    font-weight: normal;
    color: #222;
}
.text10 {
    font-size: 10px;
    font-weight: normal;
    color: #222;
}
.bold {
    font-weight: 700;
}
.semi-bold {
    font-weight: 600;
}
.medium {
    font-weight: 500;
}
ul {
    list-style: none;
}

.delete-multiple {
    position: relative !important;
    opacity: 0.3 !important;
}

.multi-spin {
    position: absolute !important;
    right: 62px !important;
    top: 30px !important;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: 0px !important;
}

rso * {
    box-sizing: border-box;
}

.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

img {
    width: auto;
    max-width: 100%;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.tgl {
    display: none;
    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    &+.tgl-btn {
        box-sizing: border-box;
        &::selection {
            background: none;
        }
    }
    +.tgl-btn {
        outline: 0;
        display: block;
        width: 30px;
        height: 16px;
        position: relative;
        cursor: pointer;
        user-select: none;
        margin: 0;
        &:after,
        &:before {
            position: relative;
            display: block;
            content: "";
            width: 15px;
            height: 15px;
            top: -2px;
        }
        &:after {
            left: -2px;
        }
        &:before {
            display: none;
        }
    }
    &:checked+.tgl-btn:after {
        left: 50%;
    }
}

// themes
.tgl-light {
    +.tgl-btn {
        background: #fff;
        border-radius: 2em;
        padding: 2px;
        transition: all .4s ease;
        border: solid thin #c0c0c0;
        &:after {
            border-radius: 50%;
            background: #9E9E9E;
            transition: all .2s ease;
        }
    }
    &:checked+.tgl-btn {
        background: #9FD6AE;
        &:after {
            background: #20ae3c;
        }
    }
}

.page-wrapper {
    position: relative;
    background-color: #fff;
    min-height: calc(100vh - 115px);
}
.breadcrumbs {
    width: 100%;
    background: transparent;
    padding: 10px 17px;
    ul {
        justify-content: flex-start;
        align-items: center;
        li {
            margin-right: 0px;
            span {
                display: inline-block;
                margin: 0 6px;
                color: #adadad;
            }
            a {
                font-weight: 400;
                color: #adadad;
                font-size: 12px;
                &.active {
                    color: #1b1919;
                }
            }
        }
        li:last-child {
            a {
                color: #1b1919;
            }
        }
    }
}
footer {
    padding: 25px;
    background: white;
    justify-content: flex-end;
    p {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.4px;
        color: #cacaca;
    }
}

.form-input {
    width: 100%;
    height: 32px;
    border-radius: 3px;
    border: solid 1px #dddbda;
    background-color: #fff;
    padding: 0 10px;
    font-size: 13px;
    font-weight: 400;
    color: #111;
    &.search {
        background-image: url(assets/imgs/search.png);
        background-position: left 10px center;
        background-repeat: no-repeat;
        background-size: 14px;
        padding-left: 36px;
    }
}
.ng-select.ng-select-single {
    font-size: 14px;
    font-weight: 600;
    color: #111!important;
    .ng-select-container {
        height: 40px;
        .ng-value-container {
            .ng-input {
                top :0;
                line-height: 40px;
            }
        }
    } 
    .ng-arrow-wrapper {
        background-image: url(assets/imgs/ng-select-dropdown.svg);
        background-position: center;
        background-repeat: no-repeat;
        right: 3px;
        padding-right: 0;
        .ng-arrow {                
            border: 0;
        }
    }      
}

button {
    &.button-reg {
        min-width: 116px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        border: 0;
        gap: 10px;
        padding-inline: 10px;
    }
    &.primary {            
        background-color: #078e05;
    }
    &.secondary {
        background-color: transparent;
        border: solid 1px #078e05;
        color: #078e05;
    }
}
.delete-confirm.modal {
    top: 80px;
}

::-webkit-scrollbar-track
{
    background-color: #98949461;
}
::-webkit-scrollbar
{
    width: 4px;
    background-color: #98949461;
}
::-webkit-scrollbar-thumb
{
    background-color: #858484de;
    border: 0px solid #555555;
}
@supports (scrollbar-color: #858484de #98949461) {
    * {
    scrollbar-width: thin;
    scrollbar-color: #858484de #98949461;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #fff;
    background-image: none;
}

// cursor pointer
.cursor {
    cursor: pointer !important;
}

// cursor pointer
.no-cursor {
    cursor: default !important;
}


/* Remove IE arrow */

select::-ms-expand {
    display: none;
}


/* Custom Select */

.select {
    position: relative;
    display: flex;
    width: 100%;
    height: 46px;
    line-height: 15px;
    background: #fff;
    border: solid 1px #dfdfdf;
    overflow: hidden;
    border-radius: 3px;
    padding: 0px 5px;
}

select {
    flex: 1;
    color: #000;
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
    color: #000;
}


/* Arrow */

.select::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    padding: 13px 1em;
    background: url(assets/imgs/arrow-down.svg) 0px 10px no-repeat;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
}


/* Transition */

.select:hover::after {
    color: #f39c12;
}

//Select
// Checkbox
.form-group {
    margin-bottom: 0;
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }
    label {
        position: relative;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        color: #565656;
        margin: 0;
        padding-left: 30px;
    }
    label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #787878;
        background-color: transparent;
        width: 21px;
        height: 21px;
        border-radius: 1px;
        display: inline-block;
        position: absolute;
        vertical-align: middle;
        cursor: pointer !important;
        margin-right: 11px;
        top: -12px;
        left: 0px;
    }
    input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 8px;
        width: 5px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    input:checked+label:before {
        // background: #1d5ed8;
        // border: 1px solid #1d5ed8;
        background: #979797 !important;
        border: 1px solid #979797 !important;
    }
    input:checked+label {
        color: #b0b0b0;
    }
}

// Checkbox
// Ripple Effects
.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0)
}

.ripple:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s
}

.ripple:active:after {
    transform: scale(0, 0);
    opacity: .2;
    transition: 0s
}

//Navmenu
.sidebar-menu {
    position: fixed;
    height: 53px;
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 4px 0 #0000001a;
    z-index: 10;
    top: 0;
    padding-bottom: 0px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    .sidebar-nav {
        .navbar-default {
            background: white;
            margin-bottom: 0;
            border-color: #fff;
        }
    }
    .navbar-nav {
        flex-direction: row;
        li {
            a {
                span {
                    display: block;
                    color: #222;
                }
            }
        }
    }
}

.navbar-default .navbar-toggle {
    border-color: white;
}

.school-logo {
    text-align: center;
    padding: 7px 0px 7px 4px;
    width: 80px;
    img {
        
    }
}

.search-menu {
    padding: 0 25px 25px;
    input {
        border-radius: 3px;
        border: solid 1px #e5e5e5;
        background-color: #fcfcfc;
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: black;
        padding: 9px 10px 8px;
        background: url(assets/imgs/zoom.svg) right 10px top 10px no-repeat;
    }
}

.sidebar-menu {
    .navbar-nav {
        margin-top: 0px;
        ul {
            height: calc(100vh - 250px);
            display: block;
            overflow-y: auto;
        }
        li {
            width: auto;
            position: relative;
            .nav-act-img {
                display: none;
            }
            a {
                justify-content: center;
                width: 100%;
                align-items: center;
                flex-wrap: nowrap;
                font-size: 12px;
                font-weight: 600;
                color: #222;
                padding: 15px 18px;
                text-align: center;
                height: 53px;
                &:hover {
                    background: transparent;
                }
                img {
                    margin-right: 8px;
                    width: 24px;
                }
            }
            &.act-route {
                color: #fff;
                font-weight: 500;
                background: transparent;
                border-radius: 0;
                position: relative;
                &::before {
                    // content: '';
                    position: absolute;
                    top: 0px;
                    left: 0;
                    background: #3777da;
                    width: 2px;
                    height: 100%;
                }
                &::after {
                    // content: '';
                    position: absolute;
                    bottom: -15px;
                    right: 0;
                    background-image: url(assets/imgs/side-panel-btm.png);
                    background-position: right bottom;
                    background-repeat: no-repeat;
                    width: 38px;
                    height: 15px;
                }
                .nav-img {
                    display: none !important;
                }
                .nav-act-img {
                    display: inline-block !important;
                }
                a {
                    span {
                        color: #128e10;
                    }
                }
            }
        }
    }
}

header {
    justify-content: space-between;
    align-items: center;
    padding: 18px 22px 18px 86px;
    background: #fff;
    .hdr-lft {
        width: calc(100% - 150px);
        justify-content: flex-start;
        align-items: center;
        h4 {
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1a1818;
            margin-left: 75px;
        }
        p {
            font-size: 13px;
            font-weight: 400;
            color: #999595;
            padding-left: 25px;
        }
    }
    .alert-profile {
        justify-content: flex-end;
        align-items: center;
        width: 150px;
        padding: 9px 15px;
        .alert {
            padding: 0px;
            margin-bottom: 0px;
            border: 0px solid transparent;
            border-radius: 0px;
            a {
                width: 22px;
                position: relative;
                margin: 0;
                img {
                    width: 22px;
                }
                span {
                    position: absolute;
                    top: -15px;
                    right: -15px;
                    width: 29px;
                    height: 29px;
                    border: solid 2px white;
                    background-color: #1ba922;
                    justify-content: center;
                    border-radius: 50%;
                    align-items: center;
                    font-size: 13px;
                    font-weight: 500;
                    color: white;
                }
            }
        }
        .profile {
            width: 39px;
            .profile-img {
                width: 39px;
                height: 39px;
                border-radius: 50%;
            }
            .dropdown-img {
                width: 14px;
                height: 14px;
            }
            .fa-power-off {
                color: black;
            }
        }
    }
}

footer {
    justify-content: space-between;
    align-items: center;
    background-color: #e8e8e8;
    position: relative;
    z-index: 3;
    h4 {
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8a8a8a;
        span {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #8c8c8c;
        }
    }
    p {
        font-size: 12px;
        font-weight: normal;
        color: #a4a1a1;
    }
}

.data-none {
    display: none;
}

.all-btn {
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    width: 100%;
    background: white;
    button {
        height: 43px;
        border: solid 1px #1d5ed8;
        background-color: white;
        line-height: 43px;
        padding: 0 20px;
        font-size: 14px;
        font-weight: normal;
        color: #1d5ed8;
        &.allocate {
            background-color: #1d5ed8;
            font-weight: 600;
            color: white;
            margin-right: 10px;
        }
    }
}

@media (min-width: 768px) {
    .sidebar-nav .navbar .navbar-collapse {
        padding: 0;
        max-height: none;
    }
    .sidebar-nav .navbar ul {
        float: none;
        display: block;
    }
    .sidebar-nav .navbar li {
        float: none;
        display: block;
    }
    .sidebar-nav .navbar li a {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

@media (max-width: 767px) {
    .sidebar-menu {
        width: 100%;
        height: auto;
        .navbar-brand {
            padding: 0 15px;
            img {
                height: 45px;
            }
        }
    }
    .school-logo {
        display: none;
    }
    header {
        margin-top: 52px;
    }
    header {
        padding: 18px 22px 18px 22px;
    }
    .page-wrapper {
        padding-left: 0;
    }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
    header {
        padding: 18px 22px 18px 222px;
    }
    .search-menu {
        padding: 0 16px 25px;
    }
    .page-wrapper {
        .menu-list a:first-child {
            margin-right: 20px !important;
        }
    }
    .page-wrapper {}
}

@media (max-width: 1100px) {
    .page-wrapper {
        _padding-left: 0;
        .menu-list {
            padding: 0 16px !important;
            justify-content: space-between !important;
            a {
                width: 30%;
                text-align: center;
                &:first-child,
                &:nth-child(2) {
                    margin-right: 0 !important;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    header .hdr-lft p {
        width: 100%;
        padding-left: 0;
    }
}

@media (min-width: 1680px) {
    .maximus {
        max-width: 1280px;
        margin: 0 auto;
    }
}

@media (max-width: 1680px) {
    .maximus {
        max-width: 100%;
    }
}

// App

/*==============================================================
 For all pages
 ============================================================== */

#main-wrapper {
    width: 100%;
    overflow: hidden;
}

.page-wrapper {
    position: relative;
    transition: 0.2s ease-in;
}

.page-wrapper>.container-fluid {
    padding: 20px;
    min-height: calc(100vh - 180px);
}


/*******************
 Footer
*******************/

.footer {
    padding: 15px 20px;
}

.product-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 47px;
    .produt-left-close {
        
        .action {
            background: white !important;
            color: black !important;
            box-shadow: 1px 1px 6px #d9d5d5;
            position: fixed !important;
            left: 67px !important;
        }
    }
    .product-full {
        width: 100% !important;
        margin-left: 0px !important;
    }
    .product-left {
        // float: left;
        width: 100%;
        box-shadow: 0 2px 3px 0 #0000000d;
        background-color: #f5f5f5;
        padding: 0px;
        position: relative;
        z-index: 99;
        transition: width 300ms cubic-bezier(0.2, 0, 0, 1.19) 0s;
        position: fixed;
        top: 53px;
        left: 0;
        z-index: 2;
        .action {
            position: absolute;
            top: 70px;
            right: -17px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            box-shadow: 1px 1px 6px #d9d5d5;
            padding: 4px 0px;
            background: #1d5ed8;
            color: white;
        }
        .action:hover {
            .fa-angle-left {
                -ms-transform: rotate(180deg);
                /* IE 9 */
                -webkit-transform: rotate(180deg);
                /* Chrome, Safari, Opera */
                transform: rotate(180deg);
            }
        }        
        
        .manage-products {
            padding: 0 31px;
            h3 {
                margin-bottom: 12px;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                span {
                    font-weight: 600 !important;
                    color: #101112 !important;
                }
            }
            ul {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                list-style: none;
                li {
                    display: block;
                    a {
                        height: 47px;
                        font-size: 12px;
                        font-weight: 600;
                        color: #868686;
                        border: 0;
                        background-color: transparent;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        padding-inline: 30px;
                        &::after {
                            display: none;
                        }
                        span {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img:nth-child(2) {
                                display: none;
                            }
                        }
                        &.active {
                            color: #128e10;
                            span {
                                img {
                                    &:nth-child(1) {
                                        display: none;
                                    }
                                    &:nth-child(2) {
                                        display: inline-flex;
                                    }
                                }                                    
                            }
                        }
                    }
                    .dropdown {
                        .dropdown-toggle {
                            height: 47px;
                            font-size: 12px;
                            font-weight: 600;
                            color: #868686;
                            border: 0;
                            background-color: transparent;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;
                            padding-inline: 30px;
                            min-width: 164px;
                            &::after {
                                display: none;
                            }
                            span {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img:nth-child(2) {
                                    display: none;
                                }
                            }
                        }
                        &.show {
                            .dropdown-toggle {
                                color: #128e10;
                                border-bottom: solid 1px #02ad00;
                                border-radius: 0;
                                background-color: #fff;
                                span {
                                    img {
                                        &:nth-child(1) {
                                            display: none;
                                        }
                                        &:nth-child(2) {
                                            display: inline-flex;
                                        }
                                    }                                    
                                }
                            }
                        }
                        .dropdown-menu {
                            box-shadow: 0 2px 6px 0 #0000003f;
                            background-color: #fff;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            border-radius: 0;
                            width: 100%;
                            a {
                                margin: 0;
                                width: 100%;
                                font-size: 12px;
                                font-weight: 600;
                                color: #7d7d7d;
                                padding: 6px 16px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                height: auto;
                                &:hover {
                                    color: #006900;
                                }
                            }
                        }
                    }
                }
            }
        }
        .product-para {
            padding: 20px 10px 20px 25px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p {
                text-align: left;
                font-size: 12px;
                font-weight: normal;
                color: #8a8a8a;
                margin-bottom: 20px;
            }
        }
    }
    .product-right {
        will-change: margin-left;
        padding-bottom: 50px;
        // margin-left: 300px;
        .add-btn {
            justify-content: flex-end;
            align-items: center;
            padding: 0 20px;
            margin-bottom: 20px;
        }
    }
}

.product-add-tab {
    padding: 0 20px;
    .nav-tabs {
        background: white;
        padding: 0px 0 0 0px;
        li {
            margin: 0;
            a {
                border: 0px solid #e6e6e6;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                color: #676767;
                padding: 16px 26px 10px;
                text-transform: uppercase;
                &.active {
                    background-color: transparent;
                    border: 0px solid #1d5ed8;
                    border-bottom: solid 3px #027600;
                    color: #222;
                }
            }
        }
    }
}

.product-count {
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 18px 30px;
    width: calc(100% - 36px);
    box-shadow: 0 2px 1px 0 #00000018;
    border: solid 1px #f0f0f0;  
    background-color: white;
    padding: 8px 15px;
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        span {
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #027600;
        }
        p {
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #9f9f9f;
        }
    }
}

.search-exp {
    margin: 0 20px 20px;
    width: calc(100% - 40px);
    padding: 10px 20px;
    justify-content: space-between;
    align-items: flex-end;
    background: white;
    position: relative;
    .search-left {
        width: calc(100% - 150px);
        justify-content: flex-start;
        align-items: flex-end;
        gap: 16px;
        .search-group {
            width: 300px;    
        }        
        .input-group {
            margin: 0;
            width: auto;
            &.ig-date {
                width: 170px;
            }
        }
        a {
            width: 24px;
            margin-left: 10px;
        }
        .dropdown {
            position: static;
            button {
            
            }
        }
        .d-inline-block {
            position: relative;
            form {
                
            }
        }
        .act-filter-dropdown {
            left: -350px !important;
        }
    }
    .search-right {
        width: 140px;
        justify-content: flex-end;
        a {
            width: 97px;
            height: 46px;
            border-radius: 4px;
            border: solid 1px #cbddff;
            background: white;
            line-height: 46px;
            font-size: 14px;
            font-weight: 500;
            color: #2d76ff;
            text-align: center;
            justify-content: center;
            align-items: center;
            img {
                margin-right: 5px;
            }
        }
    }
}

.filter-apply {
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0;
    gap: 16px;
    a {
        width: auto !important;
        height: 43px;
        border: solid 1px #1d5ed8;
        background-color: #fff;
        line-height: 43px;
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        color: #1d5ed8;
        padding: 0 18px;
        &.apply {
            background-color: #1d5ed8;
            color: white;
            margin-right: 26px;
        }
    }
}

.filter-list-selected {
    justify-content: flex-start;
    align-items: center;
    padding: 7px 20px;
    .fls-col {
        justify-content: space-between;
        align-items: center;
        border-radius: 30px;
        border: solid 1px #c9d9f6;
        background-color: white;
        padding: 2px 7px;
        font-size: 12px;
        font-weight: normal;
        color: #6695ec;
        margin-right: 8px;
        a {
            font-weight: 600;
            color: rgba(0, 0, 0, 0.54);
            font-size: 12px;
            margin-left: 10px;
        }
    }
}

.filter-group {
    width: 100%;
    margin-bottom: 15px;
    p {
        font-size: 12px;
        font-weight: 600;
        color: #474747;
        margin: 0 0 3px;
        width: 100%;
        span {
            color: #dd0909;
        }
        span.tooltips {
            margin: 0 10px;
        }
    }
    input,
    textarea {
        width: 100%;
        height: 40px;
        border-radius: 3px;
        border: solid 1px #c7c7c7;
        background-color: transparent;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 600;
        color: #111;
        &.search {
            background-image: url(assets/imgs/search-ico.svg);
            background-position: right 10px center;
            background-repeat: no-repeat;
        }
        &.date {
            background-image: url(assets/imgs/calender.svg);
            background-position: right 10px center;
            background-repeat: no-repeat;
        }
    }
    textarea {
        height: auto;
    }
    .row-input-3 {
        justify-content: space-between;
        align-items: center;
        input {
            width: 30%;
        }
    }
}

.cat-sub-list::after {
    content: "\002F";
    margin: 0px 8px;
    font-size: 10px;
}

.cat-sub-list:last-child::after {
    display: none;
}

[tooltip] {
    position: relative;
    /* opinion 1 */
}


/* Applies to all tooltips */

::ng-deep .tooltip-inner {
    font-family: 'Open Sans', sans-serif!important;
}

[tooltip]::before,
[tooltip]::after {
    text-transform: none;
    /* opinion 2 */
    font-size: .9em;
    /* opinion 3 */
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
}

[tooltip]::before {
    content: '';
    border: 5px solid transparent;
    /* opinion 4 */
    z-index: 1001;
    /* absurdity 1 */
}

[tooltip]::after {
    content: attr(tooltip);
    /* magic! */
    /* most of the rest of this is opinion */
    text-align: left;
    /*
      Let the content set the size of the tooltips
      but this will also keep them from being obnoxious
      */
    min-width: 20em;
    max-width: 30em;
    overflow: hidden;
    padding: 1ch 1.5ch;
    border-radius: .3ch;
    box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
    background: #333;
    color: #fff;
    z-index: 1000;
    /* absurdity 2 */
}

.modal-holder {
    .modal-dialog {
        position: fixed;
        overflow-y: auto;
        margin: auto;
        width: 320px;
        max-width: 950px !important;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }
    &.show {
        animation-name: example;
        animation-duration: 0.5s;
    }
    &.hide {
        animation-name: reschedule;
        animation-duration: 0.6s;
    }
}

@keyframes reschedule {
    0% {
        transform: translate3d(25%, 0, 0)
    }
    100% {
        transform: scale(1)
    }
}

@keyframes example {
    0% {
        transform: translate3d(25%, 0, 0)
    }
    100% {
        transform: scale(1)
    }
}


/* Make the tooltips respond to hover */

[tooltip]:hover::before,
[tooltip]:hover::after {
    display: block;
}


/* don't show empty tooltips */

[tooltip='']::before,
[tooltip='']::after {
    display: none !important;
}


/* FLOW: RIGHT */

[tooltip][flow^="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #333;
    right: calc(0em - 5px);
    transform: translate(.5em, -50%);
}

[tooltip][flow^="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(.5em, -50%);
    font-family: 'Open Sans', sans-serif!important;
}


/* KEYFRAMES */

@keyframes tooltips-vert {
    to {
        opacity: .9;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltips-horz {
    to {
        opacity: .9;
        transform: translate(0, -50%);
    }
}


/* FX All The Things */

[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
    font-family: 'Open Sans', sans-serif!important;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
    font-family: 'Open Sans', sans-serif!important;
}

.succesfully-modal {
    .success {
        padding: 30px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
            width: 67px;
        }
        p {
            margin: 30px 0;
            font-size: 16px;
            font-weight: 600;
            color: #151313;
        }
        button {
            width: 119px;
            height: 43px;
            background-color: #1d5ed8;
            line-height: 43px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: white;
        }
    }
}

// // login and register
@import url('https://fonts.googleapis.com/css?family=K2D:200,300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    background: #fff;
}

a {
    transition: all .4s;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    -ms-transition: all .4s;
    text-decoration: none;
    color: #000;
}

a:hover {
    text-decoration: none;
    outline: none;
}

img {
    max-width: 100%;
    width: auto;
    vertical-align: middle;
}

div,
ul,
li,
header,
nav,
section,
span,
article {
    padding: 0;
    margin-bottom: 3px;
    list-style: none;
}

* {
    box-sizing: border-box;
}

.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.text-center {
    text-align: center;
}


/*Ripple Effects*/

.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0)
}

.ripple:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s
}

.ripple:active:after {
    transform: scale(0, 0);
    opacity: .2;
    transition: 0s
}


/* Reset Select */

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #fff;
    background-image: none;
}


/* Remove IE arrow */

select::-ms-expand {
    display: none;
}


/* Arrow */

.select::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    padding: 13px 1em;
    background: url(assets/imgs/arrow-down.svg) 0px 10px no-repeat;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
}


/* Transition */

header {
    //   background-image: url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-color: black;
    min-height: 500px;
    background-size: cover;
    position: relative;
}

.header-top {
    background-color: #000000;
    padding: 5px 20px;
    justify-content: flex-end;
    align-items: center;
}

.header-top a.logreg {
    font-size: 16px;
    font-weight: 400;
    color: white;
    margin: 0 30px;
    position: relative;
    z-index: 2;
}

.header-top a.vendor {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    background-color: #1c1b1b;
    font-size: 15px;
    font-weight: 600;
    color: white;
    letter-spacing: 0.3px;
    padding: 0 10px;
    position: relative;
    z-index: 2;
}

.lang {
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
    color: white;
    position: relative;
    z-index: 2;
}

.lang img {
    margin-right: 8px;
}

.logo-avl-loc {
    position: absolute;
    left: 0;
    top: 0;
    width: 464px;
    height: 470px;
    background-image: url('/assets/images/header-path.svg');
    background-repeat: no-repeat;
    background-position: left top;
    padding: 50px;
}

.logo-avl-loc a.logo {
    width: 148px;
    display: inline-block;
    margin-bottom: 30px;
}

.banner-text {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 450px;
    margin: auto;
    height: 130px;
}

.banner-text h2 {
    font-size: 36px;
    font-weight: bold;
    color: white;
    margin-bottom: 5px;
    font-family: 'Open Sans', sans-serif!important;
    text-align: right;
}

.banner-text p {
    font-size: 25px;
    font-weight: 600;
    color: white;
    margin-bottom: 0px;
    font-family: 'Open Sans', sans-serif!important;
    text-align: right;
}

.avl-location {
    display: block;
    padding-left: 20px;
}

.avl-location p {
    font-size: 16px;
    font-weight: normal;
    color: white;
    margin: 0 0 16px;
}

.avl-location .dropdown {
    width: 230px;
}

.avl-location .dropdown a.dropdown-toggle {
    width: 100%;
    border-radius: 30px;
    background: transparent;
    border: solid thin white;
    display: block;
    padding: 8px;
    font-size: 16px;
    color: white;
}

.avl-location .dropdown a.dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 20px;
}

.avl-location .dropdown .dropdown-menu {
    width: 100%;
    padding: 12px;
}

.avl-location .dropdown .dropdown-menu p {
    font-size: 14px;
    font-weight: 500;
    color: #5e5e5e;
    margin-bottom: 10px;
}

.avl-location .dropdown .dropdown-menu .select {
    height: 34px;
    border: solid 1px #bbbbbb;
    border-radius: 3px;
    margin-bottom: 10px;
}

.avl-location .dropdown .dropdown-menu input {
    height: 34px;
    width: 100%;
    border: solid 1px #bbbbbb;
    border-radius: 3px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: normal;
    color: #5e5e5e;
    padding: 0 10px;
}

.avl-location .dropdown .dropdown-menu ul {
    height: 150px;
    overflow-y: auto;
}

.avl-location .dropdown .dropdown-menu ul li a {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: normal;
    color: #5e5e5e;
}

.avl-location .dropdown .dropdown-menu ul li a span {
    font-weight: 500;
    color: #000000;
}

.bodybg {
    background-repeat: repeat;
    background-position: center;
}

.food-menu-search {
    padding: 22px 24px;
    justify-content: space-between;
    align-items: center;
}

.exo-menu {
    float: left;
    list-style: none;
    position: relative;
    background: transparent;
}

.exo-menu>li {
    display: inline-block;
    float: left;
}

.exo-menu>li>a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
}

.exo-menu>li>a.active,
.exo-menu>li>a:hover,
li.drop-down ul>li>a:hover {
    color: #fff;
    border-bottom: solid 2px #ff3f07;
}


/*responsive*/

@media (min-width:767px) {
    .exo-menu>li>a {
        display: block;
        padding: 0px;
        margin-right: 15px;
        border-bottom: solid 2px transparent;
    }
    .visible-xs-block {
        display: none;
    }
    .exo-menu {
        width: calc(100% - 270px);
    }
}

@media (max-width:767px) {
    .exo-menu {
        min-height: 58px;
        background-color: #23364B;
        width: 100%;
        margin-bottom: 20px;
    }
    .exo-menu>li>a {
        width: 100%;
        display: none;
    }
    .exo-menu>li {
        width: 100%;
    }
    .display.exo-menu>li>a {
        display: block;
        padding: 20px 22px;
    }
    .search-menu {
        width: 100% !important;
    }
}

a.toggle-menu {
    position: absolute;
    right: 0px;
    padding: 17px 20px;
    font-size: 16px;
    background-color: #ccc;
    color: #23364B;
    top: 0px;
}

.search-menu {
    width: 260px;
}

.search-menu input {
    border-radius: 3px;
    border: solid 1px #999999;
    background-color: #424244;
    width: 100%;
    height: 38px;
    line-height: 38px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    padding: 0 10px;
}

.today-special {
    padding-top: 0px;
    margin-bottom: 40px;
}

h3.title {
    font-size: 17px;
    font-weight: 800;
    color: white;
    margin: 0 0 15px;
    padding-left: 25px;
}

.ts-col .ts-content h4 {
    font-size: 16px;
    font-weight: bold;
    color: #e3161b;
    margin: 0 0 5px;
}

.ts-col .ts-content h3 {
    font-size: 17px;
    font-weight: 800;
    color: #000;
    margin: 0 0 8px;
}

.ts-col .ts-content p {
    font-size: 16px;
    font-weight: 400;
    color: #5e5e5e;
    margin: 0;
    line-height: 20px;
}

.quality-complaints {
    margin-bottom: 50px;
}

.menu-like {
    margin: 0 0 50px;
}

.menu-like .card {
    border: 0;
}

.card-img {
    height: 190px;
    justify-content: center;
    align-items: center;
}

.card-img img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.menu-like .card-body {
    min-height: 200px;
}

.menu-like .card-body h4.card-title {
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin: 0 0 10px;
    height: 52px;
    overflow: hidden;
}

.menu-like .card-body p.card-text {
    font-size: 13px;
    font-weight: 400;
    color: #696969;
    margin: 0 0 13px;
    height: 60px;
    overflow: hidden;
}

.menu-like .card-body p.card-wt {
    font-size: 13px;
    font-weight: 400;
    color: #878787;
    margin: 0 0 10px;
}

.menu-like .card-body .price-row {
    justify-content: space-between;
    align-items: center;
}

.menu-like .card-body .price-row h4 {
    font-size: 17px;
    font-weight: 700;
    color: black;
    margin: 0 0 0px;
}

.menu-like .card-body .price-row a {
    width: 128px;
    height: 35px;
    border-radius: 30px;
    background-color: #000000;
    line-height: 35px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: white;
}

footer {
    background-color: rgb(255, 255, 255);
}

footer h3 {
    font-size: 14px;
    font-weight: bold;
    color: #626262;
    margin: 0 0 5px;
}

.ftr-links {
    justify-content: space-between;
}

.ftr-links li {
    width: 45%;
}

.ftr-links li a {
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #626262;
}

.app-store {
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.ftr-contact {
    border-radius: 13px;
    background-color: #f8f8f8;
    padding: 20px;
    justify-content: space-between;
    align-items: flex-start;
}

.ftr-col-l {
    width: calc(100% - 120px);
}

.fcl-top {
    margin-bottom: 20px;
}

.fcl-top h4 {
    font-size: 13px;
    font-weight: normal;
    color: #626262;
    margin: 0;
}

.fcl-top p {
    font-size: 13px;
    font-weight: normal;
    color: #626262;
    margin: 0;
}

.mb0 {
    margin-bottom: 0;
}

.ftr-col-s {
    justify-content: space-between;
    align-items: center;
    width: 100px;
}


/*login*/

.sign-logo {
    background-position: right bottom;
    height: 270px;
    padding: 40px;
}

.sign-wrapper {
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.sign-row {
    justify-content: space-between;
    align-items: center;
    width: 1020px;
}

.sign-left {
    width: 450px;
}

.sign-form {
    width: 500px;
}

.sign-form h2 {
    font-size: 40px;
    font-weight: bold;
    color: #000000;
    margin: 0 0 42px;
}

.input-group {
    margin-bottom: 15px;
}

.input-group {
    input {
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 3px;
        border: solid 1px #c7c7c7;
        background-color: #fff;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 600;
        color: #111;
    }
    p {
        font-size: 12px;
        font-weight: 600;
        color: #474747;
        margin: 0 0 3px;
        width: 100%;
        sup {
            color: red;
        }
    }
}
.error-data {
    font-size: 12px;
    color: Red;
}
input {
    &.date {
        background-image: url(assets/imgs/calender-ico.svg);
        background-position: right 10px center;
        background-repeat: no-repeat;
    }
}

.forgot {
    justify-content: flex-end;
    align-items: center;
    margin: 5px 0 20px;
    width: 100%;
}

.forgot a {
    font-size: 16px;
    font-weight: normal;
    color: #878787;
    line-height: 1.31;
}

.signin-col {
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.signin-col a.sign-btn {
    width: 203px;
    height: 47px;
    border-radius: 3px;
    background-color: #ec4e20;
    line-height: 47px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}

.signin-col p.or {
    font-size: 20px;
    font-weight: 600;
    color: #878787;
    line-height: 24px;
    margin: 25px 0;
}

.signin-col h5 {
    font-size: 18px;
    font-weight: normal;
    color: #878787;
    line-height: 1.33;
}

.signin-col ul {
    justify-content: center;
    align-items: center;
    margin: 19px 0;
}

.signin-col ul li {
    margin: 0 10px;
}

.signin-col ul li a {
    display: block;
    width: 31px;
}

p.signup-par {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    color: #878787;
    margin: 0;
}

p.signup-par span {
    font-weight: bold;
    color: #1c1b1b;
}

.modal.fade .modal-dialog {
    width: 100%;
    right: 0px;
}

.modal-backdrop.fade {
    opacity: 0.5;
}

.modal-open .modal {
    opacity: 1;
}

.settings-lft {
    width: 300px;
    min-height: 100vh;
    background: rgba(29, 94, 216, 0.05);
    padding-top: 30px;
}

.product-wrapper .settings-right {
    // width: calc(100% - 300px);
}

.sl-menu {
    display: block;
    padding: 7px 20px;
    h3 {
        font-size: 16px;
        font-weight: normal;
        color: #000000;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
    &.active {
        background: white;
        h3 {
            padding: 10px 0 0;
        }
        .sl-menu-sub {
            display: block;
            padding: 20px 30px;
            a {
                font-size: 13px;
                font-weight: 400;
                color: #656565;
                display: block;
                margin-bottom: 10px;
                &.current {
                    font-weight: 600;
                    color: #1d5ed8;
                }
            }
        }
    }
}

// custom radio button
.radio-button-grp {
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    /* Hide the browser's default radio button */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #eee;
        border-radius: 50%;
    }
    /* On mouse-over, add a grey background color */
    .container:hover input~.checkmark {
        background-color: #ccc;
    }
    /* When the radio button is checked, add a blue background */
    .container input:checked~.checkmark {
        background-color: #2196F3;
    }
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    /* Show the indicator (dot/circle) when checked */
    .container input:checked~.checkmark:after {
        display: block;
    }
    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }
}

//21-09-2020
.select-variant {
    justify-content: space-between;
    .sv-names {
        width: 290px;
        border: solid 1px #e0e0e0;
        background-color: #fcfcfc;
        padding: 15px;
        h3 {
            font-size: 14px;
            font-weight: 500;
            color: #255d89;
            margin-bottom: 25px;
        }
        ul {
            list-style: none;
            padding: 0 0 0 20px;
            li {
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 8px;
                a {
                    font-size: 14px;
                    font-weight: normal;
                    color: #4e4e4e;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 5px;
                    img {
                        margin-right: 10px;
                    }
                }
                span {
                    font-style: italic;
                    font-size: 12px;
                    font-weight: 500;
                    color: #b3b3b3;
                }
                &.selected {
                    a {
                        font-weight: 500;
                        color: #1b62bc;
                    }
                }
            }
        }
    }
    .sv-values {
        width: calc(100% - 310px);
        border: solid 1px #e0e0e0;
        background-color: #fcfcfc;
        h3 {
            font-size: 14px;
            font-weight: 500;
            color: #255d89;
            margin-bottom: 25px;
            padding: 16px 16px 0;
        }
    }
}

.pv-row {
    margin-top: 20px;
}
.modal-dialog-centered {
    max-width: 75%;
}
input.date {
    cursor: pointer;
    background-image: url(assets/imgs/calender.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    font-size: 14px;
}
.page-title {
    padding: 16px 20px;
    h3 {
        font-size: 16px;
        font-weight: 500;
        color: #101112;
        margin: 0;
    }
}
.filter-menu {
    width: 400px !important;
}
.filter-menu {
    width: 100%;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.21);
    border: solid 1px #e6e6e6;
    padding: 26px 18px;
    border-radius: 0;
    left: 0px !important;
    .filter-title {
        font-size: 15px;
        font-weight: 500;
        color: #222222;
        margin-bottom: 10px;
    }
    h2 {
        font-size: 15px;
        font-weight: 500;
        color: #222222;
        margin-bottom: 10px;
    }
    .filter-row {
        justify-content: space-between;
        align-items: flex-end;
        .filter-innr-row {
            width: 100%;
            justify-content: flex-start;
            flex-direction: column;
        }
        .filter-category {
            width: 30%;
        }
        .act-filter-labels {
            font-weight: 450;
            font-size: 13px;
            color: #000;
            letter-spacing: -.12px;
            text-align: center;
            display: inline-block;
            width: 100%;
        }
        .date-range {
            width: 100%;
            h3 {
                font-size: 14px;
                font-weight: 600;
                color: #5d5d5d;
                margin-bottom: 9px;
            }
            .dr-row {
                justify-content: space-between;
                align-items: flex-end;
                .filter-group {
                    width: 48%;
                }
            }
        }
    }
    .filter-group {
        border: 1px solid #eee!important;
    }
    .filter-group {
        width: 100%;
        position: relative;
        margin-bottom: 20px;
        p {
            font-size: 14px;
            font-weight: normal;
            color: #5d5d5d;
            margin-bottom: 10px;
        }
        .select {
            width: 100%;
            border-radius: 0px;
        }
        input {
            border-radius: 0px;
            width: 100%;
            padding: 12px 10px 12px;
            background: transparent;
            &.date {
                background-image: url(assets/imgs/calender.svg);
                background-position: 95%;
                background-repeat: no-repeat;
            }
        }
        .input-group-append {
            position: absolute;
            right: 0;
            top: 0;
            .calendar-btn {
                
            }
        }
    }
}
.product-list-wrap {
    padding: 10px 20px;
}
.lst-xpnd {
    // position: absolute;
    // right: 0;
    // top: 14px;
    // left: 12px;
    &.ar-dwn-close {
        left: 6px;
    }
}
.active-switch {
    .switch {
        position: relative;
        display: inline-block;
        border-radius: 11px;
        border: solid 1px #d8d8d8;
        width: 30px;
        height: 16px;
        background: #d8d8d8;
        margin-bottom: 0;
        top: 5px;
    }
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        -webkit-transition: .4s;
        transition: .4s;
    }
    .slider:before {
        position: absolute;
        content: "";
        left: 0px;
        bottom: -3px;
        transition: .2s;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #d81919;
    }
    input:checked+.slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
        background-color: #20ae3c;
    }
}

.color-palatte {
    position: fixed;
    right: 0;
    top: 59px;
    background: #fff;
    border-radius: 5px 0 0 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    width: 50px;
    padding: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.21);
    z-index: 999;
    a {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        &.cp1 {
            background: #b760ea;
        }
        &.cp2 {
            background: #5a5a5a;
        }
        &.cp3 {
            background: #00cc71;
        }
        &.cp4 {
            background: #e50048;
        }
        &.cp5 {
            background: #ff7816;
        }
        &.cp6 {
            background: #00abe9;
        }
    }
}

.asidePurple {
    background: purple !important;
}

.asideGreen {
    // background: #00cc71 !important;
    background: #049e59 !important;
}

.asidePink {
    background-color: #e50048 !important;
}

.asideOrange {
    background-color: #f15c20 !important;
}

.asideBlue {
    background-color: #00abe9 !important;
}

.error-fields {
    .ng-select-container {
        border: 1px solid red !important;
    }
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
    // transform: translateZ(0);
    padding-top: 19px !important;
}

.swiper-button-next,
.swiper-button-prev {
    top: 30px !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 0 !important;
    right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    left: auto;
    right: 0 !important;
}

.aod-odh-detail .swiper-container-android .swiper-slide,
.swiper-wrapper {
    padding-top: 0px!important;
}

.aod-odh-detail .swiper-button-next,
.aod-odh-detail .swiper-button-prev {
    top: 28px!important;
}

.modal-backdrop {
    z-index: 1040 !important;
}

.password-show-hide {
    // margin-top: 19px !important;
    // position: absolute;
    // margin-left: -25px;
    // cursor: pointer;
    /* margin-top: 10px; */
    position: absolute;
    top: 30px;
    right: 10px;
    cursor: pointer;
}

.Quotation-request-model .modal-dialog {
    height: 100%;
    background: white;
    position: fixed;
    top: -28px!important;
    bottom: 0!important;
    right: 0;
    max-width: 45%!important;
    overflow-y: auto;
    overflow-x: hidden;
    width: 70%!important;
    box-shadow: -5px 2px 8px 0 #00000040;
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 0.5s;
    // -webkit-animation-fill-mode: both;
    // animation-fill-mode: both;
    .modal-content {
        padding: 0px;
        .modal-body {
            h3 {
                font-size: 20px;
                font-weight: 500;
                color: #222;
                margin: 15px 0 40px;
            }
            h4 {
                font-size: 14px;
                font-weight: 400;
                color: #222;
                margin: 0px 0 10px;
            }
            h5 {
                font-size: 18px;
                font-weight: 600;
                color: #222;
                margin: 25px 0 20px;
            }
        }
    }
}

@-webkit-keyframes slideInRight {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.allorders-filter-row {
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    margin-bottom: 16px;
    .aofrr-search {
        width: 247px;     
        .form-input {
            &.search {
                border-radius: 4px;
            }
        }           
    }
    h5 {
        font-size: 13px;
        font-weight: bold;
        color: #777;
        margin: 0;
    }
    .aofr-rht {
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        
        .dropdown {
            position: static;
            .dropdown-toggle {
                width: 81px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                border: solid 1px #dddbda;
                background-color: white;
                font-size: 13px;
                font-weight: 500;
                color: #222;
                gap: 10px;
                &::after {
                    display: none;
                }
            }
            .dropdown-menu {
                left: 0;
                top: 100%;
                width: 100%;
                border: solid 1px #d7d7d7;
                background-color: #faf9f9;
                padding: 22px 20px;
                form {
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    .input-group {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-direction: row;
                        gap: 7px;
                        width: auto;
                        margin: 0;
                        p {
                            font-size: 13px;
                            font-weight: 500;
                            color: #777;
                            margin: 0;
                            width: auto;
                        }
                        input {
                            height: 30px;
                            &.order-id, &.date {
                                width: 125px;
                            }
                            &.cus-name {
                                width: 137px;
                            }
                            &.amount {
                                width: 90px;
                            }
                            &.credited{
                                width: 100px;
                            }
                        }
                        .ng-select {
                            width: 140px;
                            .ng-select-container{
                                height: 32px;
                                min-height: 32px;
                            }
                        }
                        &.ig-choose-order {
                            .ng-select {
                                width: 200px;
                            }
                        }
                        &.ig-date {
                            .dropdown-menu {
                                min-width: 17rem;
                            }
                        }
                    }
                    button {
                        margin-left: auto;
                        width: 99px;
                        height: 32px;
                        background-color: #000;
                    }
                }
            }
            &.show {
                .dropdown-toggle {
                    border: solid 1px #d7d7d7;
                    background-color: #faf9f9;
                    border-bottom: 0;
                    height: 35px;
                    border-radius: 4px 4px 0 0;
                    position: relative;
                    z-index: 8;
                }
                .dropdown-menu {
                    top: -3px!important;
                    z-index: 3;
                }
            }
        }
        button {
            height: 32px;
        }
    }
}
.list-table-row-content {
    margin-bottom: 14px;
    h5 {
        font-size: 13px;
        font-weight: bold;
        color: #777;
        margin: 0;
    }
}
.reset-filter {
    font-size: 12px;
    color: blue !important;
    text-decoration: underline !important;
    cursor: pointer;
    margin-left: 0px;
}
.product-table {
    padding: 0 20px;
}
span.tags-action {
    display: inline-flex;
    padding: 4px 10px;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    &.tag-active {
        border: solid 1px #b5ff4e;
        background-color: #b5ff4e66;
        color: #406a06;
    }
    &.tag-inactive {
        border: solid 1px #ff754ea9;
        background-color: #ff754e77;
        color: #ad2e0a;
    }
    &.tag-cod {
        border: solid 1px #6adaf99a;
        background-color: #6adaf966;
        color: #2c6b7c;
    }
    &.tag-order-placed {
        color: #b77200;
        border: solid 1px #ffc76a;
        background-color: #ffb53966;
    }
    &.tag-inactive-outline {
        border: 0px;
        background: transparent;
        color: #ad2e0a;
    }
    &.tag-default {
        border: solid 1.2px #e1e1e1;
        background-color: #e9e9e966;
        color: #222;
        &:hover {
            border: solid 1.2px #dae8d3;
            background-color: #e8ffde66;
            color: #6f8f5f;
        }
        a {
            padding: 0 0 0 3px;
            img {
                width: 12px;
            }
        }
    }
}

.table-responsive {
    overflow-y: visible !important;
    overflow-x: visible;
    background: white;
    .order-title {
        padding: 16px;
        display: inline-block;
    }
    .olw-wrap {
        padding: 16px;
    }
    .no-data {
        display: block;
        text-align: center;
        color: gray;
        padding: 32px;
    }
    .order-list-wrap {
        padding: 0 20px;
        p.added-prd {
            font-size: 13px;
            font-weight: 500;
            color: #787878;
            margin-bottom: 4px;
            padding-left: 15px;
        }
    }
    .form-group {
        label {
            width: 21px;
            height: 21px;
            position: relative;
            &::before {
                top: 0;
                margin-right: 0;
            }
        }
    }
    .custom-control-label {
        &::before {
            width: 21px;
            height: 21px;
            top: 0;
            border-radius: 0;
        }
        &::after {
            width: 21px;
            height: 21px;
            top: 0;
        }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #444242;
        border: solid thin #444242;
    }
    table {
        thead {
            tr {
                th {
                    padding: 16px 15px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #636363;
                    background-color: #f4f4f4;
                    border: 0;
                    vertical-align: middle;
                    &.price {
                        white-space: nowrap;
                    }
                }
            }
        }
        tbody {
            tr {
                &:hover {
                    background-color: #fff8ee;
                }
                th {
                    padding: 11px 15px;
                    border-top: 0;
                    .form-group {
                        margin: 0;
                    }
                }
                td {
                    padding: 11px 15px;
                    font-size: 13px;
                    font-weight: 600;
                    color: #474747;
                    border-bottom: solid 1px #d9d9d9;
                    border-top: 0;
                    text-transform: capitalize;
                    &.utili-ven {
                        background-color: #fff7eb;
                    }
                    span.id {
                        color: #027600;
                    }
                    &.product-image {
                        img {
                            max-width: 60px;
                            max-height: 52px;
                            width: auto;
                        }
                    }
                    .toggle {
                        display: inline-flex;
                    }
                    &.cat-price {
                        p {
                            white-space: nowrap;
                        }
                    }
                    &.price {
                        white-space: nowrap;
                    }
                    &.action-btn {
                        white-space: nowrap;
                        button {
                            background: transparent;
                            border: 0;
                            img {
                                width: 16px;
                            }
                            &.btn-danger {
                                color: #fff;
                                background-color: #dc3545;
                                border-color: #dc3545;
                            }
                            &.btn-success {
                                color: #fff;
                                background-color: #198754;
                                border-color: #198754;
                            }
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 16px;
                            &.edit {
                                background-image: url(assets/imgs/edit.png);
                                width: 20px;
                                height: 20px;
                            }
                            &.delete {
                                background-image: url(assets/imgs/delete-new.png);
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    &.content-btn {
                        button {
                            display: inline-flex;
                        }
                    }
                    p {
                        margin: 0;
                    }
                    h4 {
                        font-size: 13px;
                        font-weight: 600;
                        color: #474747;
                        margin: 0;
                    }
                    .form-group {
                        margin: 0;
                    }
                    span.tags-action {
                        &.tag-inactive {
                            border: solid 0px #ff754ea9;
                            background-color: transparent;
                            color: #ad2e0a;
                        }
                    }
                    &:last-child {
                        white-space: nowrap;
                    }
                    &.btn-inline {
                        button {
                            display: inline-block;
                        }
                    }
                }                    
            }
        }
        .dropdown-toggle {
            padding: 0 0px 0 10px;
            display: inline-block;
            background-color: transparent;
            border: 0;
            img.ellipse-v {
                transform: rotate(90deg);
            }
            i {
                color: #b8cfdb;
            }
            &::after {
                display: none;
            }
        }
        .dropdown-menu {
            min-width: 116px;
            width: 116px;
            border-radius: 3px;
            box-shadow: 0 2px 4px 0 #00000038;
            background-color: white;
            padding: 0;
            // left: -87px !important;
            border: 0;
            a {
                padding: 12px 24px;
                font-size: 12px;
                font-weight: 600;
                color: #acacac;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 0 !important;
                img {
                    margin-right: 5px;
                    max-width: 11px;
                }
                &:hover {
                    color: #555;
                }
            }
        }
        tr {
            td {
                
                &.sku {
                    input {
                        width: 202px;
                        color: #777;
                    }
                }
                &.price {
                    text-align: right;
                    input {
                        width: 79px;
                        text-align: right;
                        color: #181918;
                        display: inline-block;
                    }
                }
                &.sell-prd-act {
                    white-space: nowrap;
                    button {
                        display: inline-flex;
                        width: 114px;
                        height: 33px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 2px;
                        background-color: #11aa29;
                        font-size: 12px;
                        font-weight: 500;
                        color: #fff;
                        margin-right: 10px;
                        border: 0;
                        &.update {
                            background-color: #fdc961;
                            color: #181918;
                        }
                    }
                    a {
                        display: inline-block;
                        padding: 9px;
                        vertical-align: middle;
                        font-size: 12px;
                        font-weight: 500;
                        color: #6f5218;
                    }
                }
                &.act-btn {
                    button {
                        background-color: transparent;
                        border: 0;
                        padding: 0 10px;
                        &:last-child {
                            padding-right: 0;
                        }
                        &.act-btn-cls {
                            position: relative;
                            span {
                                display: none;
                                width: 93px;
                                height: 25px;
                                // background-image: url(../../../../../../../assets/imgs/hvr-bg.svg);
                                background-image: url(assets/imgs/hvr-bg.svg);
                                background-position: center;
                                background-size: contain;
                                background-repeat: no-repeat;
                                font-size: 13px;
                                font-weight: 500;
                                color: #c8c8c8;
                                justify-content: flex-start;
                                align-items: center;
                                padding-left: 9px;
                            }
                            &:hover {
                                span {
                                    display: flex;
                                    position: absolute;
                                    left: -83px;
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
            &.sell-prd {
                td {
                    background-color: #fbf1dd4d;
                }
            }
            &.sell-prd-action {
                position: relative;
                .spa-abs {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #dedede;
                    text-align: center;
                    p {
                        font-size: 16px;
                        font-weight: 500;
                        color: #000;
                        margin: 0;
                    }
                    button {
                        display: flex;
                        width: 76px;
                        height: 35px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 2px;
                        background-color: #3da91a;
                        font-size: 14px;
                        font-weight: 600;
                        color: #fff;
                        border: 0;
                        margin-left: 14px;
                        &.no {
                            background: #fff;
                            border: solid 1px #d6d6d6;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}
.custom-checkbox {
    input {
        &:focus {
            box-shadow: 0;
        }
    }
    .custom-control-label{
        font-size: 12px;
        font-weight: 600;
        color: #474747;
        &::before {
            border: #535353 solid 2px !important;
            border-radius: 0;
            top: 0;
            background-color: transparent;
        }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #535353;
        border: #535353 solid 2px;
    }
}
.data-col {
    padding: 20px;
    .form-group {
        flex-direction: column;
        margin-bottom: 40px;
        label {
            font-size: 14px;
            font-weight: 600;
            color: #474747;
            margin: 0 0 10px;
            width: 100%;
            padding: 0;
        }
    }
    .youtube-embeded {
        justify-content: space-between;
        width: 100%;
        .video-input {
            width: 610px;
            height: 40px;
            padding: 0 0 0 13px;
            border-radius: 2px;
            border: solid 1px #d7d2d2;
            background-color: var(--white-three);
        }
        
        .video-button {
            width: 100px;
            height: 40px;
            border-radius: 2px;
            border: solid 1px #016dd9;
            background-color: #016dd9;
            color: white;
        }
    }
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #444242;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #444242;
}
.category-wrap {
    justify-content: space-between;
    padding: 20px 0;
    .category-col {
        width: 49%;
        .sub-sub-header {
            font-size: 12px;
            font-weight: 600;
            padding: 0 0 7px;
            color: #474747;
            margin: 0;
        }
        .categories-col-innr {
            border: solid 1px #c7c7c7;
            min-height: 400px;
            padding-bottom: 15px;
            .fg-row {
                width: 100%;
                input.form-input {
                    border: 0;
                    border-bottom: solid 1px #c7c7c7;
                }
            }
        }
        .add-all {
            padding: 10px 25px;
            a {
                font-size: 14px;
                font-weight: 400;
                color: #222;
                text-decoration: underline;
            }
        }
        .add-category {
            background: #fff;
            height: 312px;
            overflow-y: auto;
            padding: 0px;
            a {
                width: 100%;
                padding: 11px 35px 11px 25px;
                font-size: 14px;
                font-weight: 600;
                color: #3c3c3c;
                position: relative;
                background-color: #f5f5f5;
                margin-bottom: 2px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                    width: calc(100% - 90px);
                    margin: 0;
                }
            }
        }
    }
}
.fg-date {
    position: relative;
    margin-bottom: 0;
    .input-group-append {
        position: absolute;
        right: 0;
        top: 0;
        .btn-outline-secondary:hover {
            background-color: transparent;
        }
    }
}
.input-group-append {
    button {
        width: 48px;
        height: 40px;
        background-image: url(assets/imgs/calender.svg);
        background-position: center;
        background-repeat: no-repeat;
    }
}
ngb-datepicker-navigation-select > .form-select {
    background-color: transparent;
}

.filter-row {
    padding: 28px 25px 28px 21px;
    box-shadow: 0 2px 4px 0 #00000027;
    background-color: white;
    justify-content: space-between;
    align-items: flex-start;
    // margin-bottom: 17px;
    .form-group {
        label {
            width: 100%;
            font-size: 14px;
            font-weight: normal;
            color: #222220;
            margin-bottom: 10px;
            justify-content: space-between;
            align-items: center;
            span {}
        }
        .frm-chk {
            border: solid 1px #cccccc;
            width: 100%;
            border-top: 0;
            padding: 25px 8px 0;
            height: 200px;
            overflow-x: auto;
            h3 {
                font-size: 14px;
                font-weight: 500;
                color: #222220;
                padding-left: 31px;
            }
            .custom-control {
                margin-bottom: 10px;
                padding-left: 0.7rem;
                label {
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: normal;
                    color: #222220;
                    padding-left: 25px;
                }
                .custom-control-label::before {
                    border-radius: 0;
                    width: 13px !important;
                    height: 13px !important;
                    border: solid thin #0000008a;
                    left: 0;
                }
                .custom-control-input:checked~.custom-control-label::before {
                    background-color: #1d5ed8 !important;
                    border: #1d5ed8 !important;
                }
                .custom-control-input:checked~.custom-control-label::after {
                    left: -7px !important;
                    top: -1px !important;
                }
            }
            &.frm-chk-prd {
                padding-top: 0;
                h3 {
                    margin: 15px 0 5px;
                }
                .custom-control {
                    margin-bottom: 2px;
                    label {
                        color: #8a8a89;
                    }
                }
            }
        }
        input {
            width: 100%;
            height: 37px;
            border: solid 1px #cccccc;
            background-color: white;
            padding: 0 10px;
        }
        .form-date {
            justify-content: space-between;
            align-items: center;
            input {
                width: calc(100% - 30px);
            }
        }        
    }
    .form-cat {
        width: 28%;
        label {
            font-size: 12px;
            font-weight: 600;
            color: #474747;
            margin: 0 0 3px;
            width: 100%;
        }
    }
    .form-date {
        width: 20%;
        margin-top: -2px;
        label {
            font-size: 12px;
            font-weight: 600;
            color: #474747;
            margin: 0 0 3px;
            width: 100%;
        }
        .form-rw {
            width: 100%;
            margin-bottom: 16px;
        }
    }
    .sales-rep-btn {
        margin: 20px 0;
        width: 100%;
        justify-content: center;
        gap: 16px;
        button {
            max-width: 45%;
            min-width: 100px;
            text-transform: capitalize;
        }
    }
}
.dash-wrapper {
    background-color: #eaeaea;
    padding-top: 20px;
}
.dashboard-count {
    justify-content: space-between;
    padding: 24px;
    background-color: #f2f2f2;
    .dc-col {
        width: 23%;
        background: white;
        padding: 12px;
        h5 {
            font-size: 13px;
            font-weight: 600;
            color: #282828;
            margin: 0 0 10px;
            text-transform: uppercase;
        }
        .dc-act {
            width: 100%;
            h2 {
                font-size: 27px;
                font-weight: 500;
                color: #222222;
                padding: 0 0 10px;
                border-bottom: solid 2px #23ab1d;
                text-align: center;
                &.revenue {
                    font-size: 27px;
                    color: #23ab1d;
                    border-bottom: solid 2px #222;
                }
            }
            p {
                font-size: 13px;
                font-weight: 500;
                color: #787878;
                padding: 10px 0 0;
                margin: 0;
                text-align: center;
            }
            .select {
                border: 0px;
                width: 130px;
                display: block;
                margin: 0 auto;
                select {
                    text-transform: uppercase;
                }
            }
        }
        .dc-act-inact {
            justify-content: space-between;
            .dc-act {
                width: 40%;
                .dc-inact {
                    h2 {
                        color: #7b7b7b;
                        border-bottom: solid 2px #ff4f3c;
                    }
                    p {
                        color: #787878;
                    }
                }
            }
        }
    }
}
.top-sel-rec {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    .ts-col {
        width: 49%;
        background: white;
        padding: 16px 10px;
        margin-bottom: 9px;
        .tsc-head {
            justify-content: space-between;
            align-items: center;
            .dash-select {
                width: 120px;
            }
            .select {
                border: 0px;
                padding: 0;
                height: 25px;
                &::after {
                    background: url(assets/imgs/arrow-down.svg) -5px 5px no-repeat !important;
                }
                &.top-sell {
                    width: 200px;
                    select {
                        font-size: 13px;
                        font-weight: 500;
                        color: #282828;
                        text-transform: uppercase;
                    }
                }
                &.tis-week {
                    width: 100px;
                    select {
                        font-size: 12px;
                        font-weight: 400;
                        color: #ababab;
                        text-transform: uppercase;
                    }
                }
            }
            h3 {
                font-size: 12px;
                font-weight: 6500;
                color: #282828;
                margin: 0;
            }
        }
        .table {
            margin-top: 15px;
            tr {
                td {
                    font-size: 12px;
                    font-weight: 600;
                    color: #5b5b5b;
                    border-top: 0px;
                    padding: 10px;
                    &.txt-bold {
                        font-weight: 700;
                        color: #8a8a8a;
                    }
                    &.txt-ebold {
                        font-weight: 800;
                        color: #8a8a8a;
                    }
                    &.itl {
                        font-size: 12px;
                        font-weight: normal;
                        font-style: italic;
                        color: #1d5ed8;
                        padding: 5px 0 5px 5px;
                    }
                }
            }
        }
        .order-chart {
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            .oc-col {
                width: 49%;
                .or-count {
                    padding: 0 0 0 20px;
                    margin-bottom: 10px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #282828;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 4px;
                        width: 12px;
                        height: 12px;
                    }
                    &.oc-paid::before {
                        background-color: #62c6ff;
                    }
                    &.oc-pending::before {
                        background-color: #ef6f6f;
                    }
                    &.oc-delivered::before {
                        background-color: #52f2ba;
                    }
                }
            }
            .oc-col-chart {
                width: 47%;
            }
        }
    }
    .ts-col-full {
        width: 100%;
        background: white;
        .tsc-head {
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            .select {
                border: 0px;
                padding: 0;
                height: 35px;
                &::after {
                    background: url(assets/imgs/arrow-down.svg) -5px 5px no-repeat !important;
                }
                &.top-sell {
                    width: 60%;
                    border-radius: 12px;
                    background-color: #f6f6f6;
                    select {
                        font-size: 13px;
                        font-weight: 500;
                        color: #474747;
                        background-color: #f6f6f6;
                        padding: 0 10px;
                    }
                }
                &.tis-week {
                    width: 100px;
                    select {
                        font-size: 12px;
                        font-weight: 400;
                        color: #ababab;
                        text-transform: uppercase;
                    }
                }
            }
            h3 {
                font-size: 12px;
                font-weight: 500;
                color: #282828;
                margin: 0;
            }
        }
    }
}
.order-list-wrap {
    padding: 0 25px 25px;
}
.general-wrap {
    background-color: white;
}
.product-images {
    box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.07);
    background: white;
    min-height: 350px;
    padding: 20px;
    .pi-row {
        justify-content: flex-start;
        align-items: center;
        .pi-col {
            width: 118px;
            height: 118px;
            overflow: hidden;
            position: relative;
            margin-right: 20px;
            a.default-img {
                display: block;
            }
            img {
                width: auto;
            }
            a.close-img {
                position: absolute;
                right: 2px;
                top: 2px;
                width: 18px;
                height: 18px;
                background-color: #575757;
                text-align: center;
                font-size: 12px;
                color: white;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.pa-video-wrap {
    border: 1px solid #DEE2E6; 
    background: white; 
    min-height: 350px;
}
.main-content {
    .sub-content {
        display: inline-block;
        width: 30%;
        margin: 10px;
        .input-group {
            position: relative;
            .input-group-addon {
                position: absolute;
                left: 0;
                top: 0;
                background-color: #f1f1f1;
                width: 40px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 400;
                color: #222;
                border-radius: 3px;
            }
            input {
                padding-left: 60px;
            }
        }
    }
}
.add-variant-wrapper {
    padding: 20px 38px;
    background-color: #fff;
    .avw-header {
        width: 100%;
        box-shadow: 0 2px 8px 0 #00000021;
        background-color: white;
        padding: 13px 26px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .avwh-lft {
            justify-content: flex-start;
            align-items: flex-start;
            width: 60%;
            .avwhl-img {
                width: 59px;
            }
            .avwhl-content {
                width: calc(100% - 59px);
                h3 {
                    font-size: 18px;
                    font-weight: 500;
                    color: #181918;
                    margin: 0 0 7px;
                }
                h4 {
                    font-size: 14px;
                    font-weight: 500;
                    color: #777;
                    margin: 0 0 7px;
                }
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #777;
                    margin: 0;
                    span {
                        font-weight: 500;
                    }
                }
            }
        }
        h2 {
            font-size: 16px;
            font-weight: normal;
            color: #212121;
            margin: 0;
            span {
                font-weight: 500;
            }
        }
    }
    .avw-row {
        width: 100%;
        justify-content: space-between;
        .avwr-lft {
            width: 290px;
            border: solid 1px #e0e0e0;
            background-color: #fcfcfc;
            padding: 14px;
            h3 {
                font-size: 16px;
                font-weight: bold;
                color: #0b0b0b;
                margin-bottom: 28px;
            }
            .avwrl-con {
                padding: 0 0 0 20px;
                .custom-control {
                    padding-left: 0;
                    margin-bottom: 14px;
                }
                .custom-control-label {
                    font-size: 14px;
                    font-weight: 500;
                    color: #0b0b0b;
                    span {
                        font-weight: 400;
                        color: #afacac;
                        display: inline-block;
                        padding-left: 10px;
                        font-style: italic;
                    }
                    &::before {
                        width: 1.1rem;
                        height: 1.1rem;
                        border-radius: 1px;
                        top: 0;
                    }
                    &::after {
                        top: 3px !important;
                    }
                }
                .form-group input:checked+label {
                    font-weight: 500!important;
                    color: #0b0b0b;
                }
                .form-group input:checked+label::before {
                    background-color: #0b0b0b!important;
                }
            }
        }
        .avwr-rht {
            width: calc(100% - 310px);
            .avwrr-values {
                width: 100%;
                border: solid 1px #e0e0e0;
                background-color: #fcfcfc;
                margin-bottom: 36px;
                h3 {
                    padding: 14px 12px;
                    width: 100%;
                    font-size: 16px;
                    font-weight: bold;
                    color: #0b0b0b;
                }
            }
            .avwrr-details {
                width: 100%;
                h3 {
                    padding: 14px 12px;
                    width: 100%;
                    font-size: 16px;
                    font-weight: bold;
                    color: #0b0b0b;
                }
                .table-responsive {
                    .table {
                        tbody {
                            tr {
                                td {
                                    .avwwr-price {
                                        position: relative;
                                        input {
                                            padding-left: 25px;
                                        }
                                        span {
                                            position: absolute;
                                            left: 10px;
                                            top: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }            
        }
    }    
    .attribute-select-row {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        border: solid 1px #e0e0e0;
        background-color: #fcfcfc;
        padding: 23px 15px 23px 20px;
        margin-bottom: 31px;
        .frm-group {
            width: 35%;
            justify-content: space-between;
            align-items: center;
            gap: 17px;
            label {
                font-size: 12px;
                font-weight: 500;
                color: #181918;
            }
            .fg-inp {
                width: calc(100% - 106px);
            }
            &.fg-attr-val {
                width: calc(50% - 80px);
            }
        }
        button {
            width: 70px;
        }
    }
}
.vac-btns {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    gap: 16px;
}
.prod-dtl {
    margin-left: 15px;
    padding: 20px 20px 10px;
    width: 100%;    
    background-color: #fff;
    .prod-title {
      color: red;
      padding: 0px 20px 0px;
        font-size: 16px;
    }
    h5 {
        font-size: 14px;
    }
}
.questionmod-wrap {
    .adp-row {
        background-color: #f4f4f4;
    }
}
.cards.srl-card {
    padding: 10px 20px;
    min-height: 80vh;
    .title-card {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        background: #fff;
        padding: 20px 39px;
        h3 {
            font-size: 26px;
            font-weight: 700;
            color: #222220;
            margin: 0;
        }
        button {
            width: 83px;
            height: 37px;
            border-radius: 2px;
            background-color: #3485ed;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            border: 0;
        }
    }
}

.header-rht__toggle {
    width: 126px;
    display: flex;  
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    span {
        top: 0px;
        position: relative;
        &.hrt-dark {
            img {
                &:first-child {
                    display: none;
                }
            }
        }
    }
    .tgl-light:checked + .tgl-btn::after {
        background: #000;
    }
    .tgl-light:checked + .tgl-btn {
        background: #fff;
    }
}

.allorders-detail-wrap {
    padding: 17px 20px;
    min-height: 30vh;
    .rating-outer-wrapper{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .rating-section{
            margin-top: 24px;
            border-radius: 8px;
            background-color: #fff;
            width: 98%;
            .head-title{
                display: flex;
                gap: 5px;
                padding: 16px 24px;
                .left-part{
                    font-weight: 600;
                }
                .text{
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1.2;
                    
                }
                border-bottom: 1px solid #EBECED;
            }
            .rating-wrapper{
                padding: 16px 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .rating-content{
                    padding: 8px 12px 8px 10px;
                    background-color: #F5F6FA;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: calc(33.33% - 23px);
                    .left-part{
                        font-weight: 500;
                    }
                }
            }
            .buyer-comments{
                width: 100%;
                flex-wrap: wrap;
                .action-wrapper{
                    gap: 11px;
                    display: flex;
                    align-items: center;
                    margin-top: 16px;
                    width: 100%;
                }
                .rating-content{
                    justify-content: flex-start;
                    gap: 8px;
                    width: 100%;
                    background-color: transparent;
                    padding: 0;
                    .left-part{
                        white-space: nowrap;
                        font-weight: 400;
                    }
                    .right-part{
                        width: 100%;
                        width: 100%;
                        padding: 8px 6px;
                        border-radius: 8px;
                        background-color: #F5F6FA;
                        font-weight: 500;
                    }
                }
            }
        }
        
        .refund-details-wrapper{
            width: 33%;
            .refund-pro{
                margin: 24px 0;
                padding: 12px 0;
                text-align: center;
                color: #fff;
                background-color: #00A759;
            }
            .refund-details{
              .title-text{
                  padding: 16px 24px;
                  font-weight: 600;
                  font-size: 18px;
                  color: #000819;
                  border-bottom: 1px solid #EBECED;
                  background-color: #fff;
              }
             .details{
              padding: 0 6px 10px 6px;
              background-color: #fff;
              ul{
                 background-color: #F5F6FA;
                  li{
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      padding: 16px 18px;
                      .text{
                          font-weight: 500;
                          font-size: 16px;
                      }
                      .amount{
                          font-weight: 500;
                          font-size: 16px;
                          &.active{
                              color: #00A759;
                          }
                      }
                      .view{
                          border:none;
                          font-weight:500;
                          font-size: 16px;
                          text-decoration: underline;
                      }
                  }
              }
             }
          }
        } 
    }
    .aodw-row {
        width: 100%;
        border-radius: 7px;
        box-shadow: 0 6px 8px 0 #49474716;
        border: solid 1px #dedede;
        background-color: #fcfcfc;
        margin-bottom: 20px;
        .aodw-lft {
            width: calc(100% - 524px);
            padding: 20px 0 0 14px;
            .aodwl-row {
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 15px;
                .aodwl-img {
                    width: 41px;
                    img {
                        width: auto;
                        max-width: 100%;
                    }
                }
                .aodwl-content {
                    width: calc(100% - 41px);
                    padding-left: 15px;
                    h3 {
                        font-size: 16px;
                        font-weight: bold;
                        color: #2d2d2d;
                        margin: 0 0 1px;
                    }
                    h6 {
                        font-size: 12px;
                        font-weight: 500;
                        color: #605e5e;
                        margin: 0 0 10px;
                    }
                    .aodwlc-rw {
                        width: 100%;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 15px;
                        .aodwlc-col {
                            p {
                                font-size: 12px;
                                font-weight: 500;
                                color: #858585;
                                margin: 0 0 4px;
                            }
                            h4 {
                                font-size: 14px;
                                font-weight: 500;
                                color: #4b4b4b;
                                margin: 0;
                                &.tot {
                                    font-weight: bold;
                                    color: #222;
                                }
                            }
                        }
                    }
                }
            }
            .aodwl-btm {
                width: 100%;
                border-top: solid 2px #dfdfdf;
                a {
                    display: inline-flex;
                    padding: 7px 0;
                    font-size: 12px;
                    font-weight: normal;
                    color: #000;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 2px;
                    span {
                        width: 16px;
                        height: 16px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        color: #fff;
                    }
                }
            }
        }
        .aodw-os {
            width: 262px;
            background-color: #f8f8f8;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            padding: 20px 18px;
            position: relative;
            .aodwo-top {
                text-align: center;
                p {
                    font-size: 14px;
                    font-weight: normal;
                    color: #000;
                    margin: 0 0 9px;
                }
                h3 {
                    font-size: 20px;
                    font-weight: 500;
                    color: #000;
                    line-height: 0.95;
                    letter-spacing: 0.5px;
                    margin: 0 0 9px;
                    text-transform: uppercase;
                }
            }
            .aodwo-btm {
                display: flex;
                justify-content: flex-end;
                width: 100%;
            }
            .chg-ord-stat {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                padding: 23px 20px 20px;
                text-align: center;
                box-shadow: 0 2px 6px 0 #00000032;
                background-color: #fff;
                z-index: 1;
                a.close-st {
                    position: absolute;
                    right: 7px;
                    top: 7px;
                }
                h4 {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.36;
                    color: #000;
                }
                select {
                    max-width: 206px;
                    max-height: 35px;
                    width: 90%;
                    border-radius: 3px;
                    border: solid 1px #979797!important;
                    background-color: white;
                    font-size: 12px;
                    font-weight: normal;
                    color: #222;
                }
                button {
                    width: 103px;
                    height: 29px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                    border: solid 1px #dc4d5b;
                    background-color: #dc4d5b;
                    font-size: 14px;
                    font-weight: normal;
                    color: #fff;
                }
            }
        }
        .aodw-si {
            width: 262px;
            background-color: #f2f2f2;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            padding: 20px 18px;
            position: relative;
            .aodwsi-top {
                h5 {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.36;
                    color: #000;
                    margin: 0 0 11px;
                    text-align: center;
                }
                p {
                    font-size: 12px;
                    font-weight: 400;
                    color: #000;
                    margin: 0 0 10px;
                    text-align: left;
                }
                h6 {
                    font-size: 12px;
                    font-weight: 400;
                    color: #000;
                    margin: 0 0 0px;
                    text-align: left;
                }
                a {
                    display: block;
                    font-size: 12px;
                    font-weight: normal;
                    color: #1741e6;
                }
            }
            .aodwsi-btm {
                display: flex;
                justify-content: flex-end;
                width: 100%;
            }
            .chg-ord-stat {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                padding: 23px 20px 20px;
                text-align: center;
                box-shadow: 0 2px 6px 0 #00000032;
                background-color: #fff;
                z-index: 1;
                a.close-st {
                    position: absolute;
                    right: 7px;
                    top: 7px;
                }
                h4 {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.36;
                    color: #000;
                }
                input {
                    max-width: 206px;
                    width: 100%;
                    max-height: 35px;
                    border-radius: 3px;
                    border: solid 1px #979797;
                    // border: solid 1px #979797!important;
                    background-color: white;
                    padding: 0 11px;
                    font-size: 12px;
                    font-weight: normal;
                    color: #222;
                }
                button {
                    width: 103px;
                    height: 29px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                    border: solid 1px #dc4d5b;
                    background-color: #dc4d5b;
                    font-size: 14px;
                    font-weight: normal;
                    color: #fff;
                }
            }
        }
    }
    .aod-osh {
        width: 100%;
        border-radius: 7px;
        border: solid 1px #dedede;
        background-color: white;
        position: relative;
        a.close-st {
            position: absolute;
            right: 7px;
            top: 7px;
        }
        h4 {
            padding: 17px 19px;
            font-size: 14px;
            font-weight: normal;
            margin: 0 0 30px;
        }
        .aod-osh-row {
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            .aod-odh-lft {
                width: 54px;
            }
            .aod-odh-rht {
                width: 54px;
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

// 04-07-2022
.header-right {
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    padding-right: 10px;
    .header-sidebar-profile {
        .dropdown {
            .dropdown-toggle {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                font-weight: 500;
                color: #222;
                span {
                    width: 43px;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }
            .dropdown-menu {
                padding: 0;
                box-shadow: 0 4px 6px 0 #0000003f;
                border-radius: 0;
                border: 0;
                top: 46px!important;
                transform: translate3d(-42px, 0px, 0px)!important;
                a {
                    font-size: 12px;
                    font-weight: 600;
                    color: #7d7d7d;
                    padding: 12px 16px;
                    &:hover {
                        color: #006900;
                    }
                }
            }
        }
    }
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: transparent;
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(0,123,255,0);
}

// Dark

.dark {
    background-color: #0e0f0d;
    h2,h3,h4,h5,h6,.page-title,.header,.sub-header,.sub-sub-header {
        color: #fff;
    }   
    .text16,.text14,.text12,.text10 {
        color: #8BA5B8;
    }
    .breadcrumbs ul li:last-child a {
        color: #6f6f6f;
    }
    .form-input,textarea.form-control {
        background-color: transparent;
        border: 1px solid #597182;
        color: #456479;
        &.search {
            background-image: url(assets/imgs/search-lite.svg);            
        }
    }
    .input-group {
        p {
            color: #8ba5b8;
        }
        input {
            background-color: transparent;
            border: 1px solid #597182;
            color: #456479;
        }
    }
    .filter-group {
        p {
            color: #8ba5b8;
        }
    }
    input.date {
        background-image: url(assets/imgs/calender-dark.png);
    }
    .page-wrapper {
        background-color: #0e0f0d;
    }
    .product-count {
        background-color: #272727;
        border: solid 1px #272727;
        box-shadow: 0 2px 4px 0 #00000020;
    }
    .sidebar-menu {
        box-shadow: 0 2px 4px 0 #0000001a;
        background-color: #1c1c1c;
        .navbar-nav {
            li {
                a {
                    span {
                        color: #e7e7e7;
                    }
                }
                &.act-route {
                    a {
                        span {
                            color: #128e10;
                        }
                    }
                }
            }
        }
    }
    .dashboard-count {
        background-color: #121311;
        .dc-col {
            background-color: #272727;
            h5 {
                color: #9f9f9f;
            }
            .dc-act {
                h2 {
                    color: #027600;
                    border-bottom: solid 2px #070707;
                }
                p {
                    color: #9f9f9f;
                }
            }
        }
    }
    .dash-wrapper {
        background-color: #0e0f0d;
        .top-sel-rec {            
            .ts-col {
                background-color: #232323;
                .tsc-head {
                    h3 {
                        color: #a2a2a2;
                    }
                }
            }
        }
    }
    .ng-select.ng-select-single {
        color: #fff!important;
        .ng-select-container {
            border: 1px solid #597182;
            background-color: transparent;
        }
        .ng-dropdown-panel.ng-select-bottom {
            border-top-color: #597182;
            background-color: #203340;
            border: 1px solid #597182;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        }
        .ng-dropdown-panel {
            .ng-dropdown-panel-items {
                .ng-option {
                    background: #203340;
                    color: #7395AC;
                    &:hover {
                        background: #2D4454;
                        color: #fff;
                    }
                }
            }
        }
    }
    .allorders-filter-row {
        .aofr-rht {
            .dropdown {
                .dropdown-toggle {
                    border: 1px solid #597182;
                    background-color: transparent;
                    color: #597182;
                }
                .dropdown-menu {
                    background: #1e313e;
                    border: 1px solid #466473;
                    box-shadow: 0 0 20px rgba(0,0,0,.1);
                }
            }
        }
    }
    .table-responsive {
        background-color: #212020;
        .table {
            thead {
                tr {
                    th {
                        background-color: #232323;
                        color: #a2a2a2;
                    }
                }
            }
            tbody {
                tr {
                    th {
                        color: #c8c8c8;
                        border-bottom: solid 1px #070707;
                        background-color: #212020;
                    }
                    td {
                        color: #c8c8c8;
                        border-bottom: solid 1px #070707;
                        background-color: #212020;
                        h4 {
                            color: #c8c8c8;
                        }
                        .dropdown {
                            .dropdown-toggle {
                                i {
                                    color: #527389;
                                }
                            }
                            .dropdown-menu {
                                background: #1e313e;
                                border: 1px solid rgba(66,96,117,.69);
                                box-shadow: none;
                                a {
                                    color: #fff;
                                    &:hover {
                                        background: #354a58;
                                    }
                                }
                            }
                        }
                        &.action-btn {
                            button {
                                background-color: #fff;
                                border-radius: 4px;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    .dropdown-menu {
        background: #1e313e;
        border: 1px solid rgba(66,96,117,.69);
        box-shadow: none;
        a {
            color: #fff;
            &:hover {
                background: #354a58;
            }
        }
    }
    footer {
        background-color: #201f1f;
        h4 {
            
        }
    }
    .product-wrapper {
        .product-left {
            background-color: #121311;
            .manage-products {
                ul {
                    li {
                        .dropdown.show {
                            width: 100%;
                            .dropdown-toggle {
                                background-color: #1c1c1c;
                            }
                        }
                        .dropdown-menu {
                            background-color: #1c1c1c;
                            width: 100%;
                            a {
                                color: #7d7d7d;
                                &:hover {
                                    color: #fdfdfd;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .search-exp {
        background-color: #272727;
    }
    .page-item-pre, .page-item-next {
        color: #a0bed2!important;
    }
    .pagination {
        ul {
            .page-item {
                background: #1e313e!important;
                border: 1px solid rgba(66,96,117,.69)!important;
                &.active {
                    background-color: #201f1f!important;
                }
            }
        }
    }
    .Quotation-request-model {
        .modal-dialog {
            background-color: #0e0f0d;
            .modal-content {
                background-color: #0e0f0d;
                .modal-body {
                    h3,h4,h5 {
                        color: #a2a2a2;
                    }
                }
            }
        }
    }
    .added-prd {
        color: #fff;
    }
    .product-add-tab {
        .nav-tabs {
            background-color: #232323;
            border-bottom: 1px solid #070707;
            li {
                a {
                    &.active {
                        color: #fff;
                    }
                }
            }
        }
    }
    .general-wrap {
        background-color: #212020;
    }
    .filter-group input, 
    .filter-group textarea {
        color: #456479;
        border: 1px solid #597182;
    }
    .category-wrap {
        .category-col {
            .add-all {
                a {
                    color: #9f9f9f;
                }
            }
            .add-category {
                background: #0e0f0d;
                a {
                    background-color: #232323;
                    color: #9f9f9f;
                }
            }
        }
    }
    .input-group-append {
        button {
            background-image: url(assets/imgs/calender-dark.png);
        }
    }
    .ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number,
    ngb-datepicker-navigation-select > .form-select {
        color: #fff;
    }
    .ngb-dp-day.ngb-dp-today {
        .btn-light {
            color: #fff;
        }
    }
    .product-images, .pa-video-wrap {
        background-color: #212020;
    }
    .data-col {
        .form-group {
            label {
                &.mat-radio-label {
                    color: #fff;
                }                
            }
        }
        .vdo-col {
            color: #474747;
        }
        .youtube-embeded {
            .video-input {
                color: #456479;
                border: 1px solid #597182;
            }
        }
    }
    .mat-radio-outer-circle {
        border-color: #4B728D;
    }
    .mat-radio-button.mat-accent .mat-radio-inner-circle {
        background-color: #FFFFFF;
    }
    .mat-expansion-panel {
        background-color: #141313;
        .mat-expansion-panel-header-title {
            color: #fff;
        }
        .sub-content {
            label {
                color: #474747;
            }
        }
    }
    .main-content {
        .sub-content {
            .input-group {
                .input-group-addon {
                    background-color: #212020;
                    color: #fff;
                    border: solid thin #212020;
                }
            }
        }
    }
    .mat-expansion-indicator::after {
        color: #fff;
    }
    .add-variant-wrapper {
        background-color: #0e0f0d;
        .avw-header {
            background-color: #212020;
            .avwh-lft {
                .avwhl-content {
                    h3 {
                        color: #bbb;
                    }
                    h4, p {
                        color: #848484;
                    }
                }
            }
            h2 {
                color: #bbb;
            }
        }
        .avw-row {
            .avwr-lft {
                background-color: #212020;
                border: solid 1px #212020;
                h3 {
                    color: #bbb;
                }
                .avwrl-con {
                    .custom-control-label {
                        color: #bbb;
                    }
                    .form-group input:checked + label {
                        color: #fff;
                    }
                }
            }
            .avwr-rht {
                .avwrr-values {
                    background-color: #212020;
                    border: solid 1px #212020;
                    h3 {
                        color: #bbb;
                    }
                    .table-responsive {
                        .table {
                            tr {
                                th {

                                }
                            }
                        }
                    }
                }
            }
        }
        .attribute-select-row {
            background-color: #212020;
            border: solid 1px #212020;
            .frm-group {
                label {
                    color: #bbb;
                }
            }
        }
    }
    .prod-dtl {
        background-color: #212020;
        border: solid 1px #212020;
    }
    .questionmod-wrap {
        .adp-row {
            background-color: #212020;
            border: solid 1px #212020;
            .text-grp {
                p {
                    color: #bbb;
                }
            }
        }
    }
    .cards.srl-card {
        .filter-row {
            background-color: #212020;
        }        
    }
    .sl-menu{
        &.active {
            background-color: #212020;
            .sl-menu-sub a.current {
                color: #fff;
            }
        }
    }
    .comp-logo {
        p {
            color: #bbb;
        }
    }
    .bd-tab-content, .chg-pwd {
        h2 {
            color: #fff!important;
        }
    }
    .header-rht__toggle {
        span.hrt-dark {
            img {
                &:last-child {
                    display: none;
                }
                &:first-child {
                    display: flex;
                }
            }
        }
    }
    .toggle-group {
        color: #fff;
        .tgl-btn {
            color: #fff;
            span {
                color: #fff;
            }
        }
    }
    .anc-wrap {
        .anc-top-box {
            box-shadow: 0 2px 7px #ffffff1c;
        }
    }
    .ng-select.ng-select-searchable {
        > .ng-select-container {
            color: #456479;
            border: 1px solid #597182;
            background-color: transparent;
        }
    }
    .allorders-container {
        .page-title {
            h3 {
                color: #fff;
            }
        }
    }
    .allorders-detail-wrap {
        .aodw-row {
            background-color: #212020;
            border: 1px solid rgba(66,96,117,.69);
            .aodw-lft {
                .aodwl-btm {
                    border-top: 2px solid rgba(66,96,117,.69);
                    a {
                        color: #6f6f6f;
                        span {
                            margin-left: 10px;
                        }
                    }
                }
            }
            .aodw-os {
                background-color: transparent;
                .aodwo-top {
                    p {
                        color: #6f6f6f;
                    }
                    h3 {
                        color: #fff;
                    }
                }
            }
            .aodw-si {
                background-color: #0e0f0d;
                border-radius: 0 7px 7px 0;
                .aodwsi-top {
                    h5 {
                        color: #fff;
                    }
                    p, h6 {
                        color: #6f6f6f;
                    }
                }
            }
        }
    }
}

.plr-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.data-product-not-available {
    margin: auto !important;
    margin-top: 100px !important;
    max-width: 530px;
    width: 90%;
    margin: 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 144px;
    gap: 14px;
    position: relative;
    border-radius: 7px;
    background-color: #f6faff;
    padding: 35px 0;
    img {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: -60px;
    }
    p {
        font-size: 16px;
        font-weight: normal;
        color: #386aaf;
    }
    button {
        padding: 7px 11px 7px 12px;
        border-radius: 4px;      
        background-color: #386aaf;
        font-size: 13px;
        font-weight: 500;
        color: #fff;
    }
    h3{
        font-size: 18px;
        color: #386aaf;
        margin: 20px 0 0;
    }
}
sup{
    color: red;
}